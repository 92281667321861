import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { Section } from "../styledComponents/section"

const OverviewIntro = styled(Section)`
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 4.8rem 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        padding: 6.4rem 0;
    }

    p, h2 {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 8;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }
    }

    h2 {
        font-weight: 700;
        @media screen and (min-width: ${breakpoints.md}px) {
            margin: 0;
        }
    }

    ul {
        ${baseGridStyles}
        list-style-type: none;
        grid-row-gap: 0;
        margin-top: 0;
        margin-bottom: 2.4rem;
        padding-top: 0;
        padding-bottom: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-row-gap: 0.8rem;
            padding-top: 0;
            padding-bottom: 0;
            align-items: center;
            margin-bottom: 2.4rem;
        }
        li {
            grid-column: span 4;
            position: relative;
            padding-left: 1.6rem;
            @media screen and (min-width: ${breakpoints.sm}px) {
                padding-left: 0.4rem;
                &:nth-child(odd){
                    grid-column: 1 / 4;
                }
                &:nth-child(even){
                    grid-column: 4 / 8;
                }
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                padding-left: 1.2rem;
                &:nth-child(odd){
                    grid-column: 3 / 7;
                }
                &:nth-child(even){
                    grid-column: 8 / 12;
                }
            }

            &::before {
                content: ' ';
                position: absolute;
                left: -0.8rem;
                top: 0.2rem;
                display: inline-block;
                transform: rotate(38deg);
                height: 1.4rem;
                width: 0.8rem;
                border-bottom: 3px solid var(--green-strong);
                border-right: 3px solid var(--green-strong);
                @media screen and (min-width: ${breakpoints.sm}px) {
                    left: -1.6rem;
                }
                @media screen and (min-width: ${breakpoints.md}px) {
                    left: -1.2rem;
                }
            }
        }
    }
`

const LearningBlock = styled.section`
    ${baseGridStyles}

    @media screen and (min-width: ${breakpoints.sm}px) {
        /* justify-content:center; */
        align-items:center;
        /* justify-items: center; */
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        /* padding-top: 8rem; */
        /* padding-bottom: 8rem; */
        /* align-items:baseline; */
    }
    p {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 8;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }
    }
    figure {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: ${props => (props.number != '1' ? '1/4' : '4/8')};
            order: ${props => (props.number != '1' ? '1' : '2')};
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: ${props => (props.number != '1' ? '1/7' : '7/12')};
        }
    }

    div.overviewWrapper {
        display:block;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: ${props => (props.number != '1' ? '4/8' : '1/4')};
            order: ${props => (props.number != '1' ? '1' : '1')};
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: ${props => (props.number != '1' ? '7/12' : '1/6')};
        }
        /* @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 8 / 12;
        } */

        h2, ul, p {
            text-align:left;
            margin-left: 0;
            margin-bottom: 1.6rem;
            grid-column: span 4;
            @media screen and (min-width: ${breakpoints.sm}px) {
                grid-column: span 4;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                /* grid-column: 1 / 12; */
            }
        }

        ul{
            list-style-type:none;
            padding-left: 2.4rem;
            li {
                padding-left: 0;
                padding-right: 0;
                position:relative;
                padding-bottom: 1.4rem;

                &:last-of-type {
                    padding-bottom: 0;
                }
            }

            li::before {
                content: ' ';
                position: absolute;
                top: 0.2rem;
                left: -2rem;
                display: inline-block;
                transform: rotate(38deg);
                height: 1.6rem;
                width: 1rem;
                border-bottom: 4px solid var(--green-strong);
                border-right: 4px solid var(--green-strong);
            }
        }

        button {
            margin-top: 0;
            @media screen and (min-width: ${breakpoints.sm}px) {
                margin-left: 0;
            }
        }

        ul{
            @media screen and (min-width: ${breakpoints.sm}px) {
                grid-column: span 4;
            }
            @media screen and (min-width: 912px) {
                padding-left: 2rem;
            }
        }
    }
`

const BusinessJourneyBlock = styled.section`

    p {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 8;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }
    }

    div.wrapper {
        ${baseGridStyles}
        @media screen and (min-width: ${breakpoints.sm}px) {
            align-items: flex-end;
        }
       
        h2, p {
            text-align:left;
            margin-left: 0;
            margin-bottom: 1.6rem;
        }
        h2 {
            grid-column: span 4;
            @media screen and (min-width: ${breakpoints.sm}px) {
                grid-column: span 6;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: span 5;
                grid-row: 1 / 3;
            }
        }
        p{
            @media screen and (min-width: ${breakpoints.sm}px) {
                grid-column: 1 / 6;
                margin-bottom: 0;

                
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: 6 / 13;
            }
        }
        .modal-content p{
            @media screen and (min-width: ${breakpoints.sm}px) {
                margin-bottom: 1.6rem;
            }
        }
        button {
            margin-top: 0;
            
            @media screen and (min-width: ${breakpoints.sm}px) {
                justify-self: flex-end;
                grid-column: 6 / 7;
                margin-left: 0;
                height: fit-content;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: 6 / 13;
                align-self: baseline;
            }
        }

        
    }
`


const BackgroundColourBlock = styled.div `
    background:${props => ( (props.number == '0') || (props.number == '2') || (props.number == '4') ? 'var(--grey-lightest)' : '#ffffff')};

    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 4.8rem 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        padding: 6.4rem 0;
    }
`


export { OverviewIntro, LearningBlock, BusinessJourneyBlock, BackgroundColourBlock }