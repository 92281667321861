import styled from "styled-components"
import breakpoints from "../breakpoints"

const StyledHeroBanner = styled.section`
  background-color: ${props => !props.noBackground ? 'var(--light-blue-darkest)' : 'transparent'};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  ${props => props.backgroundPosition ? `${ props.backgroundPosition }` : `0 0`};
  width: 100%;
  height: ${props => props.page ? 'calc(50vh - 6.3rem)' : 'calc(100vh)'};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:before {
    content: " ";
      display: block;
      width: 100vw;
      background: ${props => !props.noBackground ? 'rgb(23,82,201)' : 'transparent'};
      background: ${props => !props.noBackground ? 'linear-gradient(270deg, rgba(23,82,201,0.5032387955182073) 0%, rgba(255,255,255,0) 73%)' : 'transparent'};
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      /* max-width: 130.4rem; */
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    background-position:  0 0;
    height: 100vh;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    background-size: ${props => props.mediumBackgroundSize ? props.mediumBackgroundSize : 'cover'};
    background-position: ${props => props.mediumBackgroundPosition ? props.mediumBackgroundPosition : '0 0'};
    height: ${props => props.page ? '35vw' : '100vh'};
    &:before {
      content: " ";
      display: block;
      width: 100vw;
      /* background: rgb(23,82,201); */
      background: transparent;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      /* max-width: 130.4rem; */
    }
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    background-size: ${props => props.largeBackgroundSize ? props.largeBackgroundSize : 'cover'};
    background-position: ${props => props.largeBackgroundPosition ? props.largeBackgroundPosition : '0 0'};
  }
`

const StyledAnimationContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
`

const ChildWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  width: 100vw;

`

export {
  StyledHeroBanner,
  StyledAnimationContainer,
  ChildWrapper,
}
