import styled from "styled-components"
import breakpoints from "./breakpoints"
import { semibold16 } from "./typography"

const HeroText = styled.div`
    max-width: 130.4rem;
    text-align: center;
    width: 90vw;
    padding: 0 1.6rem;
    /* background: #ffffffad; */
    padding: 3rem;
    border-radius: 1rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 67.2rem;
        width: 70vw;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        width: 84rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        width: 104rem;
    }

    a { 
        background: #ff7400;
        border: 2px solid var(--white);
        border-bottom: 2px solid var(--white);
        border-radius: 100px;
        padding: 1.2rem;
        color: var(--white);
        display: inline-block;
        min-width: 16rem;
        ${semibold16};
        line-height: 1.6rem;
        margin: 1.6rem auto 0;
        position: relative;
        /* top:2rem; */
        text-decoration: none;
        white-space: nowrap;
        width: auto;
        &:visited {
            color: var(--white);
            background-color: transparent;
            border-bottom: 2px solid var(--white);
        }
        &:hover, 
        &:focus {
            border: 2px solid var(--white);
            border-bottom: 2px solid var(--white);
            cursor: pointer;
            color: var(--white);
            background: #00000036;
        }
        &:disabled {
            background: var(--light-blue-darkest);
            cursor: auto;
        }

        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 15.8rem;
            margin: 1.6rem auto 0;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            margin: 2.4rem auto 0;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            width: 16.5rem;
        }
    }
`

const HeroVideo = styled.div`
    max-width: 130.4rem;
    text-align: center;
    width: 100vw;
    padding: 0 1.6rem;
    /* background: #ffffffad; */

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 67.2rem;
        width: 70vw;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        width: 64.5rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        width: 85rem;
    }

    
`

const HeroHeader = styled.h1`
    color: var(--white);
    margin: 0;
    font-size: 5.2rem;
    line-height: 6.4rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
        font-size: 6rem;
        line-height: 6rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        font-size: 8.8rem;
        line-height: 10rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        font-size: 11.2rem;
        line-height: 12rem;
    }
`

const HeroDescription = styled.p`
    color: var(--white);
    margin: 1.6rem 0 0;
    font-size: 3rem;
    line-height: 3.8rem;
    font-weight: 700;
    @media screen and (min-width: ${breakpoints.md}px) {
        margin: 2.4rem 4rem 0;
        font-size: 3.8rem;
        line-height: 5rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        margin: 2.4rem 8.4rem 0;
        font-weight: 500;
        font-size: 4.6rem;
        line-height: 5.2rem;
    }
`

export { HeroText, HeroHeader, HeroVideo, HeroDescription }