import styled from "styled-components"
import breakpoints from "./breakpoints"
import Modal from "styled-react-modal";
import { StyledButton } from "./button";

const StyledModal = Modal.styled`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: block;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: ${(props) => props.opacity};
    transition : all 0.3s ease-in-out;
    overflow: auto;
    padding: 1.6rem;
    text-align: left;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 85vw;
        height: 80vw;
        padding: 2.4rem;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        width: 65vw;
        height: 45vw;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        width: 55vw;
    }

    & h2:first-of-type{
        margin-top: -3.2rem;
        margin-bottom: 1.6rem;
        padding-right: 3rem;
    }

    div, ul, h2, h3, h4, h5 {   
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 12;
        }
    }
    p {
        // width: 100%;
        margin-bottom: 1.6rem;
    }
    ul, ol {
        padding-left: 2rem;
    }


    &.order-modal {
        h2 {
            text-align: left;
            border-bottom: 1px solid var(--grey-mid);
            padding-bottom: 1.4rem;

            svg {
                fill: var(--light-blue-darkest);
                position: relative;
                top: 0.7rem;
                height: 2.5rem;
                width: 15rem;
                @media screen and (min-width: ${breakpoints.md}px) {
                height: 3.5rem;
                width: 22rem;
                }
              }
        }
    }
`;

const StyledModalCloseButton = styled(StyledButton)`

`

export {StyledModal, StyledModalCloseButton}