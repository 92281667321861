import React from "react"
import {
  StyledHeroBanner,
  ChildWrapper,
} from "../styledComponents/heroBannerStyles/heroBanner"

const HeroBanner = ({ 
    children, 
    image, 
    backgroundPosition, 
    page, 
    noBackground, 
    largeBackgroundPosition, 
    largeBackgroundSize, 
    mediumBackgroundPosition, 
    mediumBackgroundSize 
  }) => {
  return (
    <>
        <StyledHeroBanner 
          noBackground={noBackground}
          image={image}
          backgroundPosition={backgroundPosition}
          page={page}
          mediumBackgroundPosition={mediumBackgroundPosition}
          mediumBackgroundSize={mediumBackgroundSize}
          largeBackgroundPosition={largeBackgroundPosition}
          largeBackgroundSize={largeBackgroundSize}>  
          <ChildWrapper>{children}</ChildWrapper>
        </StyledHeroBanner>
    </>
  )
}

export default HeroBanner
